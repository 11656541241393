import { IApiEnvelope, IMinhaReceita, IOperacaoRenave, IPaginatedFilters, IRenave, IRenaveEntry, IRenaveExitRequest, IRenaveMock, IRenavePDF, IViaCEP } from 'src/app/auth/interfaces';
import { OnInit, Component } from '@angular/core';
import { RenaService } from 'src/app/service/rena.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-renave',
  templateUrl: './renave.component.html',
  styleUrls: ['./renave.component.css']
})

export class RenaveComponent implements OnInit {
  public dataSourcePendentes:IRenave[] = [];
  public dataSourceConfirmados:IRenave[] = [];
  public dataSourceFinalizados:IRenave[] = [];
  public environment:string = environment.envFlag;
  private username: string = (document.getElementById('username') as HTMLTdsHeaderDropdownListUserElement).imgAlt;
  public inlineTabIndex: number = 0;
  private filtros:IPaginatedFilters = { paginationValue: '1', paginationSize: '10', columnKey: '', sortingDirection: '', query: ''};
  public tablePendentePages:number = 1;
  public tableConfirmadoPages:number = 1;
  public tableFinalizadoPages:number = 1;

  constructor(private renaService: RenaService)
  {}

  ngOnInit() {
    this.getRenavePendentesTableDataPaginated(this.filtros);
    this.getRenaveConfirmadosTableDataPaginated(this.filtros);
    this.getRenaveFinalizadosTableDataPaginated(this.filtros);
  }

  onPaginationEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = event.detail.paginationValue;

    switch (this.inlineTabIndex) {
      case 0:
        this.getRenavePendentesTableDataPaginated(this.filtros);
        break;
      case 1:
        this.getRenaveConfirmadosTableDataPaginated(this.filtros);
        break;
      case 2:
        this.getRenaveFinalizadosTableDataPaginated(this.filtros);
        break;
    }
  }

  onSortEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = '1';
    filtros.columnKey = event.detail.columnKey;
    filtros.sortingDirection = event.detail.sortingDirection;
    
    switch (this.inlineTabIndex) {
      case 0:
        (document.getElementById("tablePendenteFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getRenavePendentesTableDataPaginated(this.filtros);
        break;
        case 1:
        (document.getElementById("tableConfirmadoFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getRenaveConfirmadosTableDataPaginated(this.filtros);
        break;
        case 2:
        (document.getElementById("tableFinalizadoFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getRenaveFinalizadosTableDataPaginated(this.filtros);
        break;
    }
  }

  onFilterEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = '1';
    filtros.query = event.detail.query;
    
    switch (this.inlineTabIndex) {
      case 0:
        (document.getElementById("tablePendenteFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getRenavePendentesTableDataPaginated(this.filtros);
        break;
      case 1:
        (document.getElementById("tableConfirmadoFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getRenaveConfirmadosTableDataPaginated(this.filtros);
        break;
      case 2:
        (document.getElementById("tableFinalizadoFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getRenaveFinalizadosTableDataPaginated(this.filtros);
        break;
    }
  }

  onTabChange(event: any): void {
    this.inlineTabIndex = event.detail.selectedTabIndex;
  }

  TableAddChassiMockBtn()
  {
    Swal.fire({
      title: 'Mock de Chassi',
      footer: "Proibido utilizar as letras 'I', 'O' ou 'Q' no sufixo do chassi",
      focusConfirm: false,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      html: `
      <div style="text-align:-webkit-center">
        <div style="width:208px;margin-bottom:25px"> 
          <tds-text-field id="chassi-sufix" type="text" label="Sufixo de Chassi" label-position="outside" max-length="4">
          </tds-text-field> 
        </div>
        <div class="tds-checkbox-item" style="place-content:center">
          <input class="tds-form-input" type="checkbox" checked="checked" id="vehicle-type">
            <label class="tds-form-label" for="vehicle-type">
              Veiculo Acabado
            </label>
        </div>
      </div>`,
      preConfirm: () => {
        let chassiSufix = (document.getElementById('chassi-sufix') as HTMLTdsTextFieldElement);
        let vehicleType = (document.getElementById('vehicle-type') as HTMLInputElement).checked ? false : true;
        
        if (chassiSufix.value.toUpperCase().includes('I') || chassiSufix.value.toUpperCase().includes('O') || chassiSufix.value.toUpperCase().includes('Q')) {
          Swal.showValidationMessage(
            'Sufixo possui letras proibidas.'
          );
          return false;
        }

        limpaValidacao();
        function limpaValidacao() {
          chassiSufix.state = "default";
          chassiSufix.helper= "";
        }

        let hasError = RealizaValidacao();
        function RealizaValidacao() {
          let hasError:boolean = false;

          if (chassiSufix.value == '') {
            chassiSufix.state = "error";
            chassiSufix.helper= "obrigatório";
            hasError = true;
          }

          return hasError;
        }

        if (hasError) {
          Swal.showValidationMessage(
            'Preencher campos obrigatórios.'
          );
          return false;
        }
        else
        {
          let bodyData:any = {
            sufixoChassi: chassiSufix.value.toUpperCase()
          }

          this.renaService.addChassiMock(bodyData, vehicleType, this.username)
          .subscribe((response:IApiEnvelope<IRenaveMock>) => {
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: 'Chassi criado.',
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });

                this.getRenavePendentesTableDataPaginated(this.filtros);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error.titulo,
                  text: response.error.mensagemParaUsuarioFinal,
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });
              }
          });

          return true;
        }
      }
    });
  }

  TableEntryBtn(selectedVehicle:IRenave)
  {
    Swal.fire({
      title: "Entrar com veículo zero km em estoque",
      width: 600,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      html: `
      <div class="tds-container">
        <div class="tds-row" style="margin-bottom:25px">
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-text-field id="vehicle-chassi" label="Chassi" label-position="outside" read-only>
              </tds-text-field> 
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-text-field id="vehicle-odometer" label="Quilometragem Hodômetro" label-position="outside" read-only>
              </tds-text-field> 
            </div>
          </div>
        </div>
        <div class="tds-row">
          <div class="tds-col-sm-6" style="margin-bottom:10px">
            <div style="width:208px"> 
              <tds-datetime type="date" id="vehicle-entry-date" label="Data de Entrada em Estoque">
              </tds-datetime>  
            </div>
          </div>
          <div class="tds-col-sm-6" style="margin-bottom:10px">
            <div style="width:208px"> 
              <tds-datetime type="date" id="vehicle-odometer-date" label="Data de Medição do Hodômetro">
              </tds-datetime>  
            </div>
          </div>
        </div>
        <div class="tds-row">
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-datetime type="time" id="vehicle-entry-time"></tds-datetime>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-datetime type="time" id="vehicle-odometer-time"></tds-datetime>
            </div>
          </div>
        </div>
      </div>
      `,
      willOpen: () => {
        (document.getElementById('vehicle-chassi') as HTMLInputElement).value = selectedVehicle.nr_chassi;
        (document.getElementById('vehicle-odometer') as HTMLInputElement).value = "0";

        if (this.environment) { // Development environment
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0');

          (document.getElementById('vehicle-entry-date') as HTMLInputElement).value = `${today.getFullYear()}-${mm}-${dd}`;
          (document.getElementById('vehicle-entry-time') as HTMLInputElement).value = `${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}:01`;
          (document.getElementById('vehicle-odometer-date') as HTMLInputElement).value = `${today.getFullYear()}-${mm}-${dd}`;
          (document.getElementById('vehicle-odometer-time') as HTMLInputElement).value = `${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}:01`;
        } else {
          this.renaService.getOperacaoRenaveData(selectedVehicle.nr_chassi)
          .subscribe((response:IOperacaoRenave[]) => {
            response = response.filter(item => item.datacancelamento === null);

            (document.getElementById('vehicle-entry-date') as HTMLInputElement).value = response[0].dataentradaestoque.split("T")[0];
            (document.getElementById('vehicle-entry-time') as HTMLInputElement).value = response[0].dataentradaestoque.split("T")[1];
            (document.getElementById('vehicle-odometer-date') as HTMLInputElement).value = response[0].dataentradaestoque.split("T")[0];
            (document.getElementById('vehicle-odometer-time') as HTMLInputElement).value = response[0].dataentradaestoque.split("T")[1];
          });
        }
      },
      preConfirm: () => {
        let entryDate = (document.getElementById('vehicle-entry-date') as HTMLTdsTextFieldElement);
        let entryTime = (document.getElementById('vehicle-entry-time') as HTMLTdsTextFieldElement);
        let odometerDate = (document.getElementById('vehicle-odometer-date') as HTMLTdsTextFieldElement);
        let odometerTime = (document.getElementById('vehicle-odometer-time') as HTMLTdsTextFieldElement);
        let veiculoAcabado =  selectedVehicle.fl_veiculo_acabado == "Acabado" ? true : false;

        limpaValidacao();
        function limpaValidacao() {
          entryDate.state = "default";
          entryDate.helper= "";
          entryTime.state = "default";
          entryTime.helper= "";
          odometerDate.state = "default";
          odometerDate.helper= "";
          odometerTime.state = "default";
          odometerTime.helper= "";
        }

        let hasError = RealizaValidacao();
        function RealizaValidacao() {
          let hasError:boolean = false;

          if (entryDate.value == '') {
            entryDate.state = "error";
            entryDate.helper= "obrigatório";
            hasError = true;
          }
          if (entryTime.value == '') {
            entryTime.state = "error";
            entryTime.helper= "obrigatório";
            hasError = true;
          }
          if (odometerDate.value == '') {
            odometerDate.state = "error";
            odometerDate.helper= "obrigatório";
            hasError = true;
          }
          if (odometerTime.value == '') {
            odometerTime.state = "error";
            odometerTime.helper= "obrigatório";
            hasError = true;
          }

          return hasError;
        }

        if (hasError) {
          Swal.showValidationMessage(
            'Preencher campos obrigatórios.'
          );
          return false;
        }
        else
        {
          let entry:IRenaveEntry = { 
            chassi: selectedVehicle.nr_chassi,
            dataEntradaEstoque: `${entryDate.value}T${entryTime.value}`,
            dataHoraMedicaoHodometro: `${odometerDate.value}T${odometerTime.value}`,
            quilometragemHodometro: parseInt((document.getElementById('vehicle-odometer') as HTMLInputElement).value),
          }

          this.renaService.entry(entry, veiculoAcabado, this.username)
          .subscribe((response:IApiEnvelope) => {
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: 'Veículo confirmado.',
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              });
              this.getRenavePendentesTableDataPaginated(this.filtros);
              this.getRenaveConfirmadosTableDataPaginated(this.filtros);
              (document.getElementById('tabsHeader') as HTMLTdsInlineTabsElement).selectedIndex = 1;
              this.inlineTabIndex = 1;
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error.titulo,
                text: response.error.mensagemParaUsuarioFinal,
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              });
            }
          });
          return true;
        }
      }
    });
  }

  TableCancelEntryBtn(selectedVehicle:IRenave)
  {
    Swal.fire({
      title: 'Cancelar Entrada de Estoque?',
      text: `Chassi ${selectedVehicle.nr_chassi} retornará para o estado anterior`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#16417f',
      preConfirm: () => {
        let veiculoAcabado =  selectedVehicle.fl_veiculo_acabado == "Acabado" ? true : false;
        
        this.renaService.entryCancel(selectedVehicle.id_estoque!, veiculoAcabado, this.username)
        .subscribe((response:IApiEnvelope) => {
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: 'Entrada Cancelada.',
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });

            this.getRenavePendentesTableDataPaginated(this.filtros);
            this.getRenaveConfirmadosTableDataPaginated(this.filtros);
            (document.getElementById('tabsHeader') as HTMLTdsInlineTabsElement).selectedIndex = 0;
            this.inlineTabIndex = 0;
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error.titulo,
              text: response.error.mensagemParaUsuarioFinal,
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            })
          }
        });
      }
    });
  }

  TableExitBtn(selectedVehicle:IRenave)
  {
    Swal.fire({
      title: "Sair com veículo zero km de estoque",
      width: 1200,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#16417f',
      html: `
      <div class="tds-container" style="display:inline-flex">
        <div class="tds-col-md-6">
          <div class="tds-row" style="margin-bottom:10px">
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field id="vehicle-stock-id" label="Estoque Id" label-position="outside" read-only>
                </tds-text-field> 
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field id="vehicle-chassi" label="Chassi" label-position="outside" read-only>
                </tds-text-field> 
              </div>
            </div>
          </div>
          <div class="tds-row" style="margin-bottom:30px">
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="text" id="email-scania" label="E-mail Estabelecimento" label-position="outside" read-only>
                </tds-text-field>
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="text" id="vehicle-tax-benefit" label="Benefício Tributário" label-position="outside" read-only>
                </tds-text-field>
              </div>
            </div>
          </div>
          <div class="tds-row" style="margin-bottom:10px">
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="number" max-length=44 id="vehicle-nf" label="Chave Nota Fiscal" label-position="outside">
                </tds-text-field>  
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-datetime type="date" id="vehicle-sale-date" label="Data Venda" label-position="outside">
                </tds-datetime>  
              </div>
            </div>
          </div>
          <div class="tds-row" style="margin-bottom:10px">
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="number" id="vehicle-sale-value" label="Valor Venda" label-position="outside" class="tds-align-helper" helper="Use ponto para separar somente os centavos (100000.99✔ 1̶0̶0̶.̶0̶0̶0̶,̶9̶9̶✘)">
                </tds-text-field>  
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px;margin-top: 25px;"> 
                <tds-datetime type="time" id="vehicle-sale-time" style="margin-top:24px"></tds-datetime>  
              </div>
            </div>
          </div>
        </div>

        <div class="tds-col-md-6">
          <div class="tds-row" style="margin-bottom:10px">
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field id="buyer-name" label="Razão Social" label-position="outside">
                </tds-text-field> 
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field id="buyer-email" label="E-mail Comprador" label-position="outside">
                </tds-text-field> 
              </div>
            </div>
          </div>
          <div class="tds-row" style="margin-bottom:10px">
            <div class="tds-col-sm-6">
              <div style="width:208px">
                <div class="tds-dropdown revert-select">
                  <div class="text-field-label-outside sc-tds-text-field">
                    <div class="sc-tds-text-field">
                      Tipo de Documento
                    </div>
                  </div>
                  <select name="nativeDropdown" id="buyer-doc-type" class="text-field-input text-field-input-lg sc-tds-text-field" style="height:55px">
                    <option disabled>Selecione</option>
                    <option value="CNPJ">CNPJ</option>
                    <option value="CPF">CPF</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="number" id="buyer-doc" label="Número Documento" label-position="outside">
                  <tds-icon id="cnpj-search" slot="suffix" name="search" size="20px" title="Consultar CNPJ" style="cursor:pointer;display:none"></tds-icon> 
                </tds-text-field>
              </div>
            </div>
          </div>
          <div class="tds-row" style="margin-bottom:10px">
            <div class="tds-col-sm-6">
              <div style="width:208px">
                <tds-text-field type="number" id="buyer-cep" label="CEP" label-position="outside" max-length="8">
                  <tds-icon id="cep-search" slot="suffix" name="search" size="20px" title="Consultar CEP" style="cursor:pointer"></tds-icon> 
                </tds-text-field>  
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="text" id="buyer-street" label="Logradouro" label-position="outside">
                </tds-text-field>  
              </div>
            </div>
          </div>
          <div class="tds-row" style="margin-bottom:10px">
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="text" id="buyer-number" label="Número" label-position="outside">
                </tds-text-field>  
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px"> 
                <tds-text-field type="text" id="buyer-neighborhood" label="Bairro" label-position="outside">
                </tds-text-field> 
              </div>
            </div>
          </div>
          <div class="tds-row">
            <div class="tds-col-sm-6">
              <div style="width:208px">
                <tds-text-field type="text" id="buyer-complement" label="Complemeto" label-position="outside">
                </tds-text-field>
              </div>
            </div>
            <div class="tds-col-sm-6">
              <div style="width:208px">
                <tds-text-field type="number" id="town-code" label="Código de Município" label-position="outside">
                </tds-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      `,
      willOpen: () =>{
        (document.getElementById('vehicle-chassi') as HTMLInputElement).value = selectedVehicle.nr_chassi;
        (document.getElementById('vehicle-stock-id') as HTMLInputElement).value = selectedVehicle.id_estoque!.toString();
        (document.getElementById('email-scania') as HTMLInputElement).value = "admrenavam.br@scania.com";
        (document.getElementById('vehicle-tax-benefit') as HTMLInputElement).value = "NAO_TEM";
        let cepSearch = (document.getElementById('cep-search') as HTMLInputElement);
        let cnpjSearch = (document.getElementById('cnpj-search') as HTMLInputElement);
        let numeroDocumento = (document.getElementById('buyer-doc') as HTMLTdsTextFieldElement);

        if (this.environment) { // Development environment
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0');

          (document.getElementById('vehicle-sale-value') as HTMLInputElement).value = "100000.99";
          (document.getElementById('vehicle-sale-date') as HTMLInputElement).value = `${today.getFullYear()}-${mm}-${dd}`;
          (document.getElementById('vehicle-sale-time') as HTMLInputElement).value = `${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}:01`;
          (document.getElementById('vehicle-nf') as HTMLInputElement).value = "35081059104901000176550110000000026504483966";
          (document.getElementById('buyer-name') as HTMLInputElement).value = "Besoura Montadora";
          (document.getElementById('buyer-email') as HTMLInputElement).value = "besoura@email.com";
          (document.getElementById('buyer-doc-type') as HTMLSelectElement).value = "CNPJ";
          cnpjSearch.style.display = 'block';    
          cepSearch.style.display = 'none'; 
          numeroDocumento.value = "11552671000181";
          numeroDocumento.maxLength = 14;
          (document.getElementById('buyer-cep') as HTMLInputElement).value = "06000179";
          (document.getElementById('buyer-street') as HTMLInputElement).value = "Rua do Besouro Suco";
          (document.getElementById('buyer-number') as HTMLInputElement).value = "77";
          (document.getElementById('buyer-neighborhood') as HTMLInputElement).value = "Rua do Limoeiro";
          (document.getElementById('town-code') as HTMLInputElement).value = "7107";
        } else {
          this.renaService.getOperacaoRenaveData(selectedVehicle.nr_chassi)
          .subscribe((response:IOperacaoRenave[]) => {
            response = response.filter(item => item.datacancelamento === null);
            (document.getElementById('vehicle-sale-value') as HTMLInputElement).value = response[0].valorvenda.toString().replace(',','.');
            (document.getElementById('vehicle-sale-date') as HTMLInputElement).value = response[0].datavenda?.split("T")[0]!;
            (document.getElementById('vehicle-sale-time') as HTMLInputElement).value = response[0].datavenda?.split("T")[1]!;
            (document.getElementById('vehicle-nf') as HTMLInputElement).value = response[0].chavenotafiscal;
            (document.getElementById('buyer-name') as HTMLInputElement).value = response[0].razaosocial;
            (document.getElementById('buyer-email') as HTMLInputElement).value = response[0].emailnfe;
            (document.getElementById('buyer-doc-type') as HTMLSelectElement).value = response[0].tipodocumento;
            switch (response[0].tipodocumento) {
              case "CNPJ":
                cnpjSearch.style.display = 'block';    
                cepSearch.style.display = 'none';
                numeroDocumento.maxLength = 14;    
                break;
              case "CPF":
                cnpjSearch.style.display = 'none';  
                cepSearch.style.display = 'block';  
                numeroDocumento.maxLength = 11;    
                break;
            }
            numeroDocumento.value = response[0].numerodocumento;
            (document.getElementById('buyer-cep') as HTMLInputElement).value = response[0].cep;
            (document.getElementById('buyer-street') as HTMLInputElement).value = response[0].endereco;
            (document.getElementById('buyer-number') as HTMLInputElement).value = response[0].numero;
            (document.getElementById('buyer-neighborhood') as HTMLInputElement).value = response[0].bairro;
            (document.getElementById('town-code') as HTMLInputElement).value = response[0].municcodigoserpro;
          });
        }
      },
      didOpen: () =>{
          let cep = (document.getElementById('buyer-cep') as HTMLTdsTextFieldElement);
          let cepSearch = (document.getElementById('cep-search') as HTMLInputElement);
          let cnpj = (document.getElementById('buyer-doc') as HTMLTdsTextFieldElement);
          let docType = (document.getElementById('buyer-doc-type') as HTMLSelectElement);
          let cnpjSearch = (document.getElementById('cnpj-search') as HTMLInputElement);
          let razaoSocial = (document.getElementById('buyer-name') as HTMLInputElement);
          let numeroDocumento = (document.getElementById('buyer-doc') as HTMLTdsTextFieldElement);
          
          docType.addEventListener('change', function() {
            switch (this.value) {
              case "CNPJ":
                cnpjSearch.style.display = 'block';    
                cepSearch.style.display = 'none';
                numeroDocumento.maxLength = 14;    
                break;
              case "CPF":
                cnpjSearch.style.display = 'none';  
                cepSearch.style.display = 'block';  
                numeroDocumento.maxLength = 11;    
                break;
            }
          });

          let that = this;
          cepSearch.onclick = function(){
            that.renaService.getViaCEP(cep.value.toString())
            .subscribe({
              next: (response: IViaCEP) => {
                if (response.erro) {
                  cep.state = "error";
                  Swal.showValidationMessage(
                    `CEP ${cep.value.substring(0, 5)}-${cep.value.substring(5)} inválido.`
                  );
                } else {
                  (document.getElementById('buyer-street') as HTMLInputElement).value =  response.logradouro;
                  (document.getElementById('buyer-neighborhood') as HTMLInputElement).value = response.bairro;
                  (document.getElementById('buyer-complement') as HTMLInputElement).value = response.complemento;
                  (document.getElementById('buyer-number') as HTMLInputElement).value = '';
                  (document.getElementById('town-code') as HTMLInputElement).value = response.siafi;
                  cep.state = "default";
                  Swal.resetValidationMessage();
                }
              },
              error: (error) => {
                if (!error.ok) {
                  cep.state = "error";
                  Swal.showValidationMessage(
                    `CEP ${cep.value.substring(0, 5)}-${cep.value.substring(5)} inválido.`
                  );
                }
              }
            });
          }

          cnpjSearch.onclick = function(){
            that.renaService.getMinhaReceita(cnpj.value.toString())
            .subscribe({
              next: (response: IMinhaReceita) => {
                cnpj.state = "default";
                cep.value =  response.cep;
                razaoSocial.value = response.razao_social;
                (document.getElementById('buyer-street') as HTMLInputElement).value =  `${response.descricao_tipo_de_logradouro} ${response.logradouro}`;
                (document.getElementById('buyer-neighborhood') as HTMLInputElement).value = response.bairro;
                (document.getElementById('buyer-complement') as HTMLInputElement).value = response.complemento;
                (document.getElementById('buyer-number') as HTMLInputElement).value = response.numero;
                (document.getElementById('town-code') as HTMLInputElement).value = response.codigo_municipio;
                Swal.resetValidationMessage();
              },
              error: (error) => {
                if (error.status === 400) {
                  cnpj.state = "error";
                  Swal.showValidationMessage(
                    error.error.message
                  );
                }
              }
            });
          }
      },
      preConfirm: () => {
        let tipoBeneficioTributario = (document.getElementById('vehicle-tax-benefit') as HTMLTdsTextFieldElement);
        let chaveNotaFiscal = (document.getElementById('vehicle-nf') as HTMLTdsTextFieldElement);
        let dataVenda = (document.getElementById('vehicle-sale-date') as HTMLTdsTextFieldElement);
        let horarioVenda = (document.getElementById('vehicle-sale-time') as HTMLTdsTextFieldElement);
        let valorVenda = (document.getElementById('vehicle-sale-value') as HTMLTdsTextFieldElement);
        let emailEstabelecimento = (document.getElementById('email-scania') as HTMLTdsTextFieldElement);
        let nome = (document.getElementById('buyer-name') as HTMLTdsTextFieldElement);
        let email = (document.getElementById('buyer-email') as HTMLTdsTextFieldElement);
        let tipoDocumento = (document.getElementById('buyer-doc-type') as HTMLSelectElement);
        let numeroDocumento = (document.getElementById('buyer-doc') as HTMLTdsTextFieldElement);
        let cep = (document.getElementById('buyer-cep') as HTMLTdsTextFieldElement);
        let logradouro = (document.getElementById('buyer-street') as HTMLTdsTextFieldElement);
        let numero = (document.getElementById('buyer-number') as HTMLTdsTextFieldElement);
        let complemento = (document.getElementById('buyer-complement') as HTMLTdsTextFieldElement);
        let bairro = (document.getElementById('buyer-neighborhood') as HTMLTdsTextFieldElement);
        let codigoMunicipio = (document.getElementById('town-code') as HTMLTdsTextFieldElement );

        const inputs = [
          chaveNotaFiscal,
          valorVenda,
          dataVenda,
          horarioVenda,
          nome,
          cep,
          numero,
          email,
          numeroDocumento,
          logradouro,
          bairro,
          codigoMunicipio
        ];

        limpaValidacao();
        function limpaValidacao() {
          inputs.forEach(input => {
            input.state = "default";
            if (input === valorVenda) {
              input.helper = "Use ponto para separar somente os centavos (100000.99✔ 1̶0̶0̶.̶0̶0̶0̶,̶9̶9̶✘)";
            } else {
              input.helper = '';
            }
          });
        }

        let hasError = RealizaValidacao();
        function RealizaValidacao() {
          let hasError:boolean = false;
          inputs.forEach(input => {
            if (input.value === '' || (input === cep || input === numeroDocumento || input === chaveNotaFiscal) && input.value.length !== input.maxLength) {
              input.state = "error";
              if (input !== valorVenda) {
                input.helper= "obrigatório";
              }
              hasError = true;
            }
          });
          return hasError;
        }

        if (hasError) {
          Swal.showValidationMessage(
            'Preencher campos obrigatórios.'
          );
          return false;
        }
        else
        {
          let exit:IRenaveExitRequest = { 
            idEstoque: selectedVehicle.id_estoque!,
            tipoBeneficioTributario: tipoBeneficioTributario.value,
            chaveNotaFiscal: chaveNotaFiscal.value,
            dataVenda: `${dataVenda.value}T${horarioVenda.value}`,
            valorVenda: parseFloat(valorVenda.value),
            emailEstabelecimento: emailEstabelecimento.value,
            comprador: {
              nome: nome.value,
              email: email.value,
              tipoDocumento: tipoDocumento.value,
              numeroDocumento: numeroDocumento.value,
              endereco: {
                cep: cep.value,
                logradouro: logradouro.value,
                numero: numero.value,
                complemento: complemento.value,
                bairro: bairro.value,
                codigoMunicipio: parseInt(codigoMunicipio.value)
              },
            }
          }
          
          this.renaService.exit(exit, selectedVehicle.fl_veiculo_acabado.toLowerCase(), selectedVehicle.nr_chassi, this.username)
          .subscribe((response:IApiEnvelope) => {
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: 'Veículo finalizado.',
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              });

              this.getRenaveConfirmadosTableDataPaginated(this.filtros);
              this.getRenaveFinalizadosTableDataPaginated(this.filtros);
              (document.getElementById('tabsHeader') as HTMLTdsInlineTabsElement).selectedIndex = 2;
              this.inlineTabIndex = 2;
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error.titulo,
                text: response.error.mensagemParaUsuarioFinal ?? response.error.detalhe,
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              });
            }
          });
         
          return true;
        }
      }
    });
  }
  
  TableCancelExitBtn(selectedVehicle:IRenave)
  {
    Swal.fire({
      title: 'Cancelar Saída de Estoque?',
      text: `Chassi ${selectedVehicle.nr_chassi} retornará para o estado anterior`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#16417f',
      preConfirm: () => {
        let veiculoAcabado =  selectedVehicle.fl_veiculo_acabado == "Acabado" ? true : false;

        this.renaService.exitCancel(selectedVehicle.fl_veiculo_acabado.toLowerCase(), selectedVehicle.id_estoque!, veiculoAcabado, this.username)
        .subscribe((response:IApiEnvelope) => {
        if (response.success) {
          Swal.fire({
            icon: 'success',
            title: 'Saída Cancelada.',
            toast: true,
            position: 'bottom-end',
            timer: 10000,
            timerProgressBar: true,
            showConfirmButton: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });

          this.getRenaveConfirmadosTableDataPaginated(this.filtros);
          this.getRenaveFinalizadosTableDataPaginated(this.filtros);
          (document.getElementById('tabsHeader') as HTMLTdsInlineTabsElement).selectedIndex = 1;
          this.inlineTabIndex = 1;
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error.titulo,
              text: response.error.mensagemParaUsuarioFinal,
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
          }
        })
      }
    });
  }

  TablePDFBtn(selectedVehicle:IRenave)
  {
    let requestSuffix = "";

    if (selectedVehicle.fl_veiculo_acabado == "Acabado") {
      requestSuffix = `pdf-atpv?chassi=${selectedVehicle.nr_chassi}`
    } else {
      requestSuffix = `pdf-termo?idEstoque=${selectedVehicle.id_estoque}`
    }

    this.renaService.getPDFData(requestSuffix)
    .subscribe((response:IApiEnvelope<IRenavePDF>) => {
      if (response.success) {
        let base64 = "";

        if (selectedVehicle.fl_veiculo_acabado == "Acabado") {
          base64 = response.data.pdfAtpvBase64!;
        }else{
          base64 = response.data.pdfBase64!;
        }

        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let file = new Blob([byteArray], {type: 'application/pdf'});
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else
      {
        Swal.fire({
          icon: 'error',
          title: response.error.titulo,
          text: response.error.mensagemParaUsuarioFinal ?? response.error.detalhe,
          toast: true,
          position: 'bottom-end',
          timer: 10000,
          timerProgressBar: true,
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });
      }
    });
  }

  getRenavePendentesTableDataPaginated(filtros: IPaginatedFilters){
    this.renaService.getRenaveTableDataPaginated(1, filtros)
    .subscribe(response => {
      this.dataSourcePendentes = response.data;
      this.tablePendentePages = response.totalPages;

      response.data.forEach(item => {
        delete item.id_estoque;
      });
    })
  }

  getRenaveConfirmadosTableDataPaginated(filtros: IPaginatedFilters){
    this.renaService.getRenaveTableDataPaginated(2, filtros)
    .subscribe(response => {
      this.dataSourceConfirmados = response.data;
      this.tableConfirmadoPages = response.totalPages;

      response.data.forEach(item => {
        item.ds_observacao = item.ds_observacao ?? "";
      });
    })
  }

  getRenaveFinalizadosTableDataPaginated(filtros: IPaginatedFilters){
    this.renaService.getRenaveTableDataPaginated(3, filtros)
    .subscribe(response => {
      this.dataSourceFinalizados = response.data;
      this.tableFinalizadoPages = response.totalPages;
      
      response.data.forEach(item => {
        //@ts-ignore
        item.id_estoque = item.id_estoque ?? "";
        item.ds_observacao = item.ds_observacao ?? "";
      });
    })
  }
}

import { Component } from '@angular/core';
import { IApiEnvelope, IPaginatedFilters, ISolicitacaoEnvio } from 'src/app/auth/interfaces';
import { RenaService } from 'src/app/service/rena.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-solicitacao-envio',
  templateUrl: './solicitacao-envio.component.html',
  styleUrls: ['./solicitacao-envio.component.css']
})
export class SolicitacaoEnvioComponent {
  public tableData:ISolicitacaoEnvio[] = [];
  public tablePages:number = 1;
  public tableDataLoteZero:ISolicitacaoEnvio[] = [];
  public tablePagesLoteZero:number = 1;
  private filtros:IPaginatedFilters = { paginationValue: '1', paginationSize: '10', columnKey: '', sortingDirection: '', query: ''};
  private username: string = (document.getElementById('username') as HTMLTdsHeaderDropdownListUserElement).imgAlt;
  public inlineTabIndex: number = 0;

  constructor(private renaService: RenaService) {
  }

  ngOnInit() {
    this.getSolicitacaoEnvioLoteZeroPaginated(this.filtros);
    this.getSolicitacaoEnvioPaginated(this.filtros);
  }
  
  onPaginationEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = event.detail.paginationValue;

    switch (this.inlineTabIndex) {
      case 0:
        this.getSolicitacaoEnvioLoteZeroPaginated(this.filtros);
        break;
      case 1:
        this.getSolicitacaoEnvioPaginated(this.filtros);
        break;
    }
  }

  onSortEvent(event: any) {
    let filtros = this.filtros;
    filtros.columnKey = event.detail.columnKey;
    filtros.paginationValue = '1';
    filtros.sortingDirection = event.detail.sortingDirection;
    
    switch (this.inlineTabIndex) {
      case 0:
        (document.getElementById("tablePagesLoteFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getSolicitacaoEnvioLoteZeroPaginated(this.filtros);
        break;
      case 1:
        (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getSolicitacaoEnvioPaginated(this.filtros);
        break;
    }
  }

  onFilterEvent(event: any) {
    let filtros = this.filtros;
    filtros.query = event.detail.query;
    filtros.paginationValue = '1';
    
    switch (this.inlineTabIndex) {
      case 0:
        (document.getElementById("tablePagesLoteFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getSolicitacaoEnvioLoteZeroPaginated(this.filtros);
        break;
      case 1:
        (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
        this.getSolicitacaoEnvioPaginated(this.filtros);
        break;
    }
  }

  onTabChange(event: any): void {
    this.inlineTabIndex = event.detail.selectedTabIndex;
  }

  TableAddSolicitacaoBtn()
  {
    Swal.fire({
      title: 'Criar Solicitação de Envio',
      showCancelButton: true,
      width: 600,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      html: `
      <div class="tds-container">
        <div class="tds-row">
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-text-field type="text" id="id-chassi" label="Chassi Id" label-position="outside" max-length="7">
              </tds-text-field>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Tipo de Manutenção
                  </div>
                </div>
                <select name="nativeDropdown" id="man-type" class="text-field-input text-field-input-lg sc-tds-text-field" style="height:55px">
                  <option value="" disabled selected>Selecione</option>
                  <option value="I">Inclusão</option>
                  <option value="E">Exclusão</option>
                  <option value="A">Alteração</option>
                </select>
              </div>
              <div id="man-type-error" class="sc-tds-text-field text-field-helper-error-state" style="color:var(--tds-text-field-helper-error);font:var(--tds-detail-05);padding-top:4px;display:none">
                <tds-icon class="sc-tds-text-field hydrated" name="error" size="16px"></tds-icon>obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>`,
      preConfirm: () => {
        let chassi = (document.getElementById('id-chassi') as HTMLTdsTextFieldElement);
        let manType = (document.getElementById('man-type') as HTMLSelectElement);
        let manTypeError = (document.getElementById('man-type-error') as HTMLDivElement);
        
        limpaValidacao();
        function limpaValidacao() {
          chassi.state = "default";
          chassi.helper= "";
          manType.style.borderBottom = "";
          manTypeError.style.display = "none";
        }

        let hasError = RealizaValidacao();
        function RealizaValidacao() {
          let hasError:boolean = false;
          if (chassi.value.length !== chassi.maxLength) {
            chassi.state = "error";
            chassi.helper= "obrigatório";
            hasError = true;
          }
          if (manType.value == '') {
            manType.style.borderBottom = "1px solid red";
            manTypeError.style.removeProperty("display");
            hasError = true;
          }
          
          return hasError;
        }

        if (hasError) {
          Swal.showValidationMessage(
            'Preencher campos obrigatórios.'
          );
          return false;
        }
        else
        {
          let solic:any = { 
            id_chassi: chassi.value,
            tp_manut: manType.value,
            observ: `Operador: ${this.username}`
          }

          this.renaService.solicitaEnvio(solic)
            .subscribe((response:IApiEnvelope) => {
              if (typeof(response.data) ==  'object') {
                Swal.fire({
                  icon: 'success',
                  title: 'Solicitação criada.',
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });

                this.getSolicitacaoEnvioLoteZeroPaginated(this.filtros);
              }  else if (typeof(response.data) ==  'string') {
                Swal.fire({
                  icon: 'error',
                  title: response.data,
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                })
              }
            });
            
          return true;
        }
      }
    });
  }

  TableRemoveSolicitacaoBtn(rmsolic:ISolicitacaoEnvio)
  {
    Swal.fire({
      title: `Remover solicitação para chassi ${rmsolic.iD_CHASSI}?`,
      text: `Solicitação de ${rmsolic.tP_MANUT} será removida.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#16417f',
      preConfirm: () => {
        if (rmsolic.lote != 0) {
          Swal.fire({
            icon: 'error',
            title: 'Remoção negada para Lote diferente de 0.',
            toast: true,
            position: 'bottom-end',
            timer: 10000,
            timerProgressBar: true,
            showConfirmButton: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
        }
        else
        {
          let solic:any = {
            iD_CHASSI: rmsolic.iD_CHASSI,
            tP_MANUT: rmsolic.tP_MANUT.substring(0, 1),
            lote: rmsolic.lote,
            observ: rmsolic.observ
          }
  
          this.renaService.removeSolicitacaoEnvio(solic)
            .subscribe((response) => {
              debugger;
              if (response.status == 204) {
                Swal.fire({
                  icon: 'success',
                  title: 'Solicitação removida.',
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });
  
                this.getSolicitacaoEnvioLoteZeroPaginated(this.filtros);
              }
            });
        }
      }
    });
  }

  getSolicitacaoEnvioLoteZeroPaginated(filtros: IPaginatedFilters){
    this.renaService.getSolicitacaoEnvioLoteZeroPaginated(filtros)
    .subscribe(response => {
        this.tableDataLoteZero = response.data.map(item => this.transformItem(item));
        this.tablePagesLoteZero = response.totalPages;
    });
  }

  getSolicitacaoEnvioPaginated(filtros: IPaginatedFilters){
    this.renaService.getSolicitacaoEnvioPaginated(filtros)
    .subscribe(response => {
        this.tableData = response.data.map(item => this.transformItem(item));
        this.tablePages = response.totalPages;
    });
  }

  transformItem(item: any): any {
    item.tP_MANUT = this.mapManutType(item.tP_MANUT);
    item.dT_SOLIC = this.formatDate(item.dT_SOLIC);
    return item;
  }

  mapManutType(type: string): string {
    const manutTypes: { [key: string]: string } = {
      "I": "Inclusão",
      "A": "Alteração",
      "E": "Exclusão"
    };
    return manutTypes[type] || type;
  }

  formatDate(dateString: string): string {
    const dt = new Date(Date.parse(dateString));
    const pad = (num: number) => num.toString().padStart(2, "0");

    return `${pad(dt.getDate())}/${pad(dt.getMonth() + 1)}/${dt.getFullYear()} ` + 
          `${pad(dt.getHours())}:${pad(dt.getMinutes())}:${pad(dt.getSeconds())}`;
  }
}

<div class="tds-container-fluid">
  <div class="tds-row">
    <h2 class="tds-headline-02">Classificação de Modelos</h2>
  </div>
  <div class="tds-row">
      <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
        <tds-table-toolbar filter>
          <div slot="end" class="tds-u-flex tds-u-align-items-center tds-u-h-100 tds-u-gap1" style="margin-left: 8px;">
            <tds-button variant="secondary" size="sm" title="Cadastrar novos modelos" (click)="TableUploadDocBtn()">
              <tds-icon slot="icon" size="18px" name="upload"></tds-icon>
            </tds-button>
            <tds-button variant="secondary" size="sm" title="Atualizar modelos" (click)="TableUpdateDocBtn()">
              <tds-icon slot="icon" size="18px" name="redirect"></tds-icon>
            </tds-button>
            <tds-button variant="secondary" size="sm" title="Baixar dados" (click)="TableDownloadDocBtn()">
              <tds-icon slot="icon" size="18px" name="document_xls"></tds-icon>
            </tds-button>
          </div>
        </tds-table-toolbar>
          <tds-table-header>
              <tds-header-cell cell-key='cd_modelo' cell-value='Código' sortable></tds-header-cell>
              <tds-header-cell cell-key='ds_carroceria' cell-value='Carroceria'></tds-header-cell>
              <tds-header-cell cell-key='ds_modelo' cell-value='Modelo' sortable></tds-header-cell>
              <tds-header-cell cell-key='ds_marca' cell-value='Marca' sortable></tds-header-cell>
              <tds-header-cell cell-key='ds_categoria' cell-value='Categoria'></tds-header-cell>
              <tds-header-cell cell-key='ds_potencia' cell-value='Potência'></tds-header-cell>
              <tds-header-cell cell-key='ds_segmento' cell-value='Segmento'></tds-header-cell>
              <tds-header-cell cell-key='ds_tracao' cell-value='Tração'></tds-header-cell>
              <tds-header-cell cell-key='ds_pbt' cell-value='PBT'></tds-header-cell>
              <tds-header-cell cell-key='ds_cmt' cell-value='CMT'></tds-header-cell>
              <tds-header-cell cell-key="actions"></tds-header-cell>
          </tds-table-header>
          <tds-table-body>
              <tds-table-body-row *ngFor="let data of tableData">
                  <tds-body-cell cell-key="cd_modelo">{{data.cd_modelo}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_carroceria">{{data.ds_carroceria}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_modelo">{{data.ds_modelo}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_marca">{{data.ds_marca}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_categoria">{{data.ds_categoria}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_potencia">{{data.ds_potencia}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_segmento">{{data.ds_segmento}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_tracao">{{data.ds_tracao}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_pbt">{{data.ds_pbt}}</tds-body-cell>
                  <tds-body-cell cell-key="ds_cmt">{{data.ds_cmt}}</tds-body-cell>
                  <tds-body-cell cell-key="actions">
                      <div class="action-buttons">
                          <tds-icon id="edit" name="settings" size="20px" style="cursor: pointer;" (click)="TableActionBtn(data)"></tds-icon>
                      </div>
                  </tds-body-cell>
              </tds-table-body-row>
          </tds-table-body>
          <tds-table-footer id="tableFooter" pages="{{tablePages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
      </tds-table>
  </div>
</div>
